import { getRegionContext } from '../util/misc';

const REGION_CONTEXT_PARAMETER = getRegionContext();

export const HOME = `${REGION_CONTEXT_PARAMETER}`;
export const STEP_UP_CHANNELS = `${REGION_CONTEXT_PARAMETER}/step-up/channels`;
export const QUESTION_COLLECTION = `${REGION_CONTEXT_PARAMETER}/questioncollection`;
export const CHANNEL_COLLECTION = `${REGION_CONTEXT_PARAMETER}/channel_collection`;
export const MOBILE_COLLECTION = `${REGION_CONTEXT_PARAMETER}/mobile_collection`;
export const LANDLINE_COLLECTION = `${REGION_CONTEXT_PARAMETER}/landline_collection`;
export const VOICE_BIOMETRICS_COLLECTION = `${REGION_CONTEXT_PARAMETER}/voicebiometrics_collection`;
export const GLOBAL_TNC_COLLECTION = `${REGION_CONTEXT_PARAMETER}/global_tnc_collection`;
export const EMAIL_COLLECTION = `${REGION_CONTEXT_PARAMETER}/email_collection`;
export const STEP_UP_VERIFICATION = `${REGION_CONTEXT_PARAMETER}/step-up/verification`;
export const CHANGE_PASSWORD = `${REGION_CONTEXT_PARAMETER}/changepassword`;
export const TERMS_AND_CONDITIONS = `${REGION_CONTEXT_PARAMETER}/termsandconditions`;
export const STEP_UP_QNA = `${REGION_CONTEXT_PARAMETER}/step-up/qna`;
export const ERROR_PAGE = `${REGION_CONTEXT_PARAMETER}/error`;
export const PASSCODE_LOGIN_VERIFICATION = `${REGION_CONTEXT_PARAMETER}/passcode/login_verification`;
export const PASSCODE_CHANNEL_VERIFICATION = `${REGION_CONTEXT_PARAMETER}/passcode/channel_verification`;
export const PASSCODE_RESEND = `${REGION_CONTEXT_PARAMETER}/passcode/resend`;
export const MOBILE_LIST_PAGE = `${REGION_CONTEXT_PARAMETER}/mobilelistpage`;
export const SUCCESS_PAGE = `${REGION_CONTEXT_PARAMETER}/success`;
export const FIDO_AUTHENTICATE_SUCCESS = `${REGION_CONTEXT_PARAMETER}/fido_authenticate_success`;
export const MESSAGE_SENT_PAGE = `${REGION_CONTEXT_PARAMETER}/message_sent`;
export const FIDO_REGISTER_SUCCESS = `${REGION_CONTEXT_PARAMETER}/fido_register_success`;
export const FIDO_TEXTLINK_PAGE = `${REGION_CONTEXT_PARAMETER}/fido_textlink`;
export const FIDO_DEVICE_REGISTER = `${REGION_CONTEXT_PARAMETER}/fido_device_register`;
export const FIDO_SETUP_CANCEL_PAGE = `${REGION_CONTEXT_PARAMETER}/fido_setup_cancelpage`;
export const FIDO_SETUP_ERROR_PAGE = `${REGION_CONTEXT_PARAMETER}/fido_setup_errorpage`;
export const FIDO_DEVICE_LIST = `${REGION_CONTEXT_PARAMETER}/fido_device_list`;
