/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import {
  SdfCloseButton, SdfIcon, SdfButton, SdfTooltip,
} from '@waypoint/react-components';
import {
  Header,
  Footer,
  LanguageDropdown,
  MainPanel,
  DynamicMessageAlert,
  AlertMessage,
} from '../../components';

import { getLocaleObjFromPropKey } from '../../util/locale';
import { getParameterByName, getReturnUrl } from '../../util/misc';
import { constants } from '../../components/login/login-comp-constants';
import { EMEA_REGION, PRODUCT_ID } from '../../util/misc/constants';
import {
  extractBrandingImageProperty,
  getOrganizationId,
  passwordRecoveryUrlCheck,
} from '../../util/misc/common';

import {
  ACCOUNTTYPE_ADP,
  LOGIN_NOT_DEFINED_ERROR,
  WELCOME_TITLE,
  ACCOUNT_LOCKED,
  ADMIN_ACCOUNT_LOCKED,
  EMPLOYEE_ACCOUNT_SUSPENDED,
  ACCOUNT_SUSPENDED,
  ERROR_TITLE,
  EMPLOYEE_ACCOUNT_INACTIVE,
  APPLICATION_ERROR_DEFAULT,
} from '../layout-constants';
import { getDefaultErrorMessage } from '../../redux/helpers/epics/error';

const { CALLING_APP_ID_BROWSER_LABEL } = constants;

const isBlank = str => (str || '').trim().length === 0;

const LoginLayout = (props) => {
  const {
    identityProviders,
    title,
    titleLockIcon,
    redirectHomeScreen,
    errorMessage,
    locale,
    mainPane,
    text,
    passwordStatus,
    passwordExpirationAt,
    isHomeScreen,
    intl,
    passwordUpdateStatus,
    changePasswordError,
    clearAlerts,
    clearPasswordAlerts,
    background,
    productLogo,
    organizationLogo,
    userid,
    passwordRecovery,
    errorCode,
    dynMsg,
    dynMsgEnabled,
    loadDynamicMessage,
    dynamicMessageLoaded,
    isAppView,
    returnToHome,
    isMobileDevice,
    titleIconName,
    iconStyle,
  } = props;

  let disableDynamicMsgKillSwitch = false;

  if (
    window.env &&
    (window.env.DISABLE_DYNAMIC_MESSAGE === 'true' || window.env.DISABLE_DYNAMIC_MESSAGE === true)
  ) {
    disableDynamicMsgKillSwitch = true;
  }
  useEffect(() => {
    if (!disableDynamicMsgKillSwitch && dynMsgEnabled && isHomeScreen && !dynamicMessageLoaded) {
      loadDynamicMessage();
    }
  }, []);

  const { id } = title.props;
  const { i18n } = text;
  const accountTypeADP =
    identityProviders && identityProviders.find(item => item.type === ACCOUNTTYPE_ADP);
  const isAccountTypeADP = accountTypeADP && accountTypeADP.type === ACCOUNTTYPE_ADP;
  const clearAlertswithDefaults = clearAlerts || false;
  const clearPasswordAlertswithDefaults = clearPasswordAlerts || false;

  const populateAlertCode = () => {
    if (!isBlank(errorMessage)) {
      return errorMessage;
    }
    if (!isBlank(changePasswordError)) {
      return changePasswordError;
    }
    if (
      !isBlank(passwordStatus) &&
      passwordStatus !== 'RESET' &&
      !clearPasswordAlertswithDefaults
    ) {
      return passwordStatus;
    }
    if (
      !isBlank(passwordUpdateStatus) &&
      !clearAlertswithDefaults &&
      !clearPasswordAlertswithDefaults
    ) {
      return passwordUpdateStatus;
    }
    return '';
  };

  let params = {};

  const getLocalizedAlert = (localizedAlertCode) => {
    if (!isBlank(localizedAlertCode) && passwordStatus === 'ABOUT_TO_EXPIRE') {
      const expiryDays = parseInt(
        (new Date(passwordExpirationAt) - new Date()) / (24 * 3600 * 1000),
        10,
      );
      const expiryDaysExcludeSameday = expiryDays === 0 ? 1 : expiryDays;
      const localeData = intl.formatMessage({
        id: localizedAlertCode,
        defaultMessage: localizedAlertCode,
      });

      return localeData.replace('[X]', expiryDaysExcludeSameday);
    }
    if (localizedAlertCode === getDefaultErrorMessage) {
      return intl.formatMessage({
        id: APPLICATION_ERROR_DEFAULT,
        defaultMessage: localizedAlertCode,
      });
    }
    return intl.formatMessage({
      id: localizedAlertCode,
      defaultMessage: `${localizedAlertCode}[]`,
    });
  };

  const alertCode = populateAlertCode();
  const signInHomeTitle = getLocaleObjFromPropKey(i18n, locale) ?
    getLocaleObjFromPropKey(i18n, locale).SIGN_IN_HOME_TITLE :
    intl.formatMessage({ id: title.props.id, defaultMessage: title.props.defaultMessage });
  const welcomeTitle =
    id === WELCOME_TITLE ?
      signInHomeTitle :
      intl.formatMessage({ id: title.props.id, defaultMessage: title.props.defaultMessage });
  const pageTitle = id === ERROR_TITLE ? '' : welcomeTitle;

  let localizedAlertContent = !isBlank(alertCode) ? getLocalizedAlert(alertCode) : '';

  const applyAdminLockMessage = () => {
    const matchData = localizedAlertContent.match(/\[(.*?)\]/);

    const linkText = matchData[1];

    const { version } = passwordRecovery || '';

    let { url } = passwordRecovery || '';

    url = url === '' || url === undefined ? passwordRecoveryUrlCheck(url) : url;

    const returnURLFromSignInAPI =
      identityProviders && identityProviders.length > 0 ? identityProviders[0].returnUrl : '';
    const returnURL = getReturnUrl() || returnURLFromSignInAPI;
    const callingAppID = getParameterByName(CALLING_APP_ID_BROWSER_LABEL);

    const backgroundImageFlt = extractBrandingImageProperty(background, locale);
    const productLogoImageFlt = extractBrandingImageProperty(productLogo, locale);
    const organizationLogoImageFlt = extractBrandingImageProperty(organizationLogo, locale);

    params = {
      callingAppId: callingAppID,
      returnUrl: returnURL,
      rtnURL: returnURL,
      userId: userid,
    };

    if (version === EMEA_REGION) {
      params = {
        ...params,
        // eslint-disable-next-line max-len
        action: url,
        locale,
        productId: getParameterByName(PRODUCT_ID) || '',
        organizationId: getOrganizationId(),
      };
    } else {
      params = {
        ...params,
        action: url,
        backgroundImageUrl: backgroundImageFlt,
        productLogoUrl: productLogoImageFlt,
        organizationLogoUrl: organizationLogoImageFlt,
      };
    }

    const FYPContent = `<button id='alertLink'>${linkText}</button>`;

    localizedAlertContent = localizedAlertContent.replace(`[${linkText}]`, FYPContent);
  };

  const organizationLogoImage = extractBrandingImageProperty(organizationLogo, locale);

  if (!isAppView && alertCode === ACCOUNT_LOCKED) {
    applyAdminLockMessage();
  }

  return (
    <div className="h-full flex flex-col justify-between bg-white md:bg-transparent p-0">
      <main
        role="main"
        className="flex-grow"
      >
        { !isAppView && organizationLogoImage && <Header { ...props } /> }
        <div
          className={
            !isAppView ? `md:mt-20 md:mb-20 ${!isHomeScreen ? 'h-full md:h-auto' : ''}` : 'h-full'
          }
        >
          { !isAppView && dynMsg && (
            <div className="md:w-2xl md:mx-auto md:mb-2">
              <DynamicMessageAlert
                dynMsg={ dynMsg }
                locale={ locale }
                isAppView={ isAppView }
              />
            </div>
          ) }
          <div
            className={
              isAppView ?
                `px-6 py-4 h-full bg-white border-0 ${
                  isHomeScreen ? 'grid items-center box-border' : ''
                }` :
                `md:w-2xl bg-white p-6 md:mx-auto md:shadow-24 md:rounded-2xl box-border ${
                  !isHomeScreen ? 'h-full md:h-auto' : ''
                }`
            }
          >
            <>
              { !isHomeScreen && (
                <div className="flex mb-2">
                  { !titleLockIcon && titleIconName && !isAppView && !isMobileDevice && (
                    <SdfIcon
                      icon={ titleIconName }
                      tabindex="0"
                      icon-title={ titleIconName }
                      className={ iconStyle || 'text-tertiary mr-2 text-2xl' }
                    />
                  ) }

                  { titleLockIcon && !isAppView && !isMobileDevice && (
                    <SdfIcon
                      icon="status-locked"
                      icon-title={ intl.formatMessage({
                        id: 'secure_lock_title',
                        defaultMessage: 'Secure page lock icon',
                      }) }
                      tabindex="0"
                      className="text-tertiary me-2 text-2xl"
                    />
                  ) }
                  <h1
                    id="login-layout_welcome"
                    className="text-heading-05 font-bold m-0 text-left pb-0"
                  >
                    { pageTitle }
                  </h1>
                  { redirectHomeScreen && !isAppView && !isMobileDevice && (
                    <SdfCloseButton
                      size="sm"
                      class="hydrated items-center"
                      style={ { marginInlineStart: 'auto' } }
                      title={ intl.formatMessage({ id: 'signin.close', defaultMessage: 'Close' }) }
                      onClick={ returnToHome }
                    />
                  ) }
                </div>
              ) }
            </>

            { !isHomeScreen && <AlertMessage { ...props } /> }
            <>
              { !isAppView && (
                <div className="flex md:justify-between justify-end">
                  { errorCode === EMPLOYEE_ACCOUNT_SUSPENDED ||
                  errorCode === ACCOUNT_SUSPENDED ||
                  errorCode === EMPLOYEE_ACCOUNT_INACTIVE ||
                  errorCode === ACCOUNT_LOCKED ||
                  errorCode === ADMIN_ACCOUNT_LOCKED ?
                    '' :
                    isHomeScreen && (
                    <div className="self-center hidden md:block">
                      <SdfTooltip
                        className="text-disabled"
                        trigger="hover"
                        state="neutral"
                        attachment-point="bottom"
                        id="signin.securepopText"
                      >
                        <SdfButton
                          id="signin.securepopTextBtnId"
                          slot="tooltip-target"
                          variant="text"
                        >
                          <SdfIcon
                            className="ml-1 text-lg text-disabled"
                            icon="status-locked"
                          />
                        </SdfButton>
                        { intl.formatMessage({
                          id: 'signin.securelock',
                          defaultMessage:
                                'Information you share, send, or receive through the site is secure. For your security, please review the URL address of your ADP website.',
                        }) }
                      </SdfTooltip>
                    </div>
                    ) }
                  { isHomeScreen && <LanguageDropdown { ...props } /> }
                </div>
              ) }
              { isAccountTypeADP ? mainPane : LOGIN_NOT_DEFINED_ERROR }
              { !isAppView && isHomeScreen && <MainPanel { ...props } /> }
            </>
          </div>
        </div>
      </main>

      <>
        <Footer { ...props } />
      </>
    </div>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.element,
  mainPane: PropTypes.element.isRequired,
  formRedirectURL: PropTypes.func,
  currentlySending: PropTypes.bool,
  setBrandingStylesLoaded: PropTypes.func,
};

LoginLayout.contextTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(LoginLayout);
