import React from 'react';
import { intlShape, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { SdfFocusPane, SdfButton, SdfIcon } from '@waypoint/react-components';
import { AllHtmlEntities as Entities } from 'html-entities';
import HTMLReactParser from 'html-react-parser';
import { constants } from './channel-collection-constants';

import { ChannelCollectionError } from '.';

const { GLOBAL_TNC, CONSENT } = constants;

const GlobalTNCCollectionForm = (props) => {
  const { challenge, saveCommunicationChannel, channelCollectionFailed } = props;

  const entities = new Entities();

  let tncConsentInfo = null;

  if (challenge && challenge.tncConsentInfo) {
    tncConsentInfo = challenge.tncConsentInfo.tncVerbiage;
  }

  const onGlobalTCAccept = () => {
    const addedCommunications = [];
    const changedCommunications = [];

    addedCommunications.push({
      type: GLOBAL_TNC,
      consentStatus: CONSENT,
    });

    saveCommunicationChannel(addedCommunications, changedCommunications);
  };

  const parseHtml = html => HTMLReactParser(html);

  return (
    (channelCollectionFailed && <ChannelCollectionError { ...props } />) || (
      <div>
        <SdfFocusPane
          size="md"
          id="GlobaltcModal"
          hideAcceptButton
          hideDismissButton
          visible
          status="none"
          spacing="loose"
          allowBackgroundInteraction
          className="h-full flex flex-column justify-center relative"
        >
          <div slot="title">
            <FormattedMessage
              id="terms_conditions_label"
              defaultMessage="Terms & Conditions"
            />
          </div>
          <div className="text-lg">{ parseHtml(entities.decode(tncConsentInfo)) }</div>
          <div
            slot="custom-buttons"
            className="flex flex-col-reverse sm:flex-row"
          >
            <SdfButton
              className="text-md ml-4"
              id="tcAcceptBtn"
              onClick={ onGlobalTCAccept }
              emphasis="primary"
            >
              <FormattedMessage
                id="terms_conditions_accept"
                defaultMessage="I Agree"
              />
              <SdfIcon
                icon="action-confirm"
                className="pl-1"
              />
            </SdfButton>
          </div>
        </SdfFocusPane>
      </div>
    )
  );
};

GlobalTNCCollectionForm.propTypes = {
  challenge: PropTypes.shape({
    tncConsentInfo: PropTypes.shape({
      tncVerbiage: PropTypes.string,
    }),
  }),
  saveCommunicationChannel: PropTypes.func.isRequired,
  channelCollectionFailed: PropTypes.bool,
  currentlySending: PropTypes.bool,
  tcChallenge: PropTypes.shape({
    text: PropTypes.string,
  }),
  gobalTcAccept: PropTypes.func.isRequired,
};

GlobalTNCCollectionForm.contextTypes = {
  intl: intlShape.isRequired,
};

export default GlobalTNCCollectionForm;
