/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';

import { injectIntl, intlShape } from 'react-intl';
import { Footer } from '../../components';

const ModalLayout = (props) => {
  const { mainPane } = props;

  return (
    <div className="h-full flex flex-col justify-between">
      <main role="main">
        <div>{ mainPane }</div>
      </main>
      <Footer { ...props } />
    </div>
  );
};

ModalLayout.propTypes = {
  children: PropTypes.element,
  mainPane: PropTypes.element.isRequired,
  formRedirectURL: PropTypes.func,
};

ModalLayout.contextTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(ModalLayout);
