/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { SdfFocusPane, SdfButton, SdfIcon } from '@waypoint/react-components';

const Entities = require('html-entities').AllHtmlEntities;
const HTMLReactParser = require('html-react-parser');

const entities = new Entities();

class TCForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
    };
  }

  render() {
    const {
      currentlySending, tcChallenge, tcCancel, tcAccept,
    } = this.props;
    const { showModal } = this.state;
    const { text: tcText } = tcChallenge;

    const onTCCancel = () => {
      !currentlySending && tcCancel();
    };

    const onTCAccept = () => {
      !currentlySending && tcAccept();
    };

    const parseHtml = html => HTMLReactParser(html);

    return (
      <SdfFocusPane
        size="md"
        id="tcModal"
        hideAcceptButton
        hideDismissButton
        visible={ showModal }
        onSdfDismiss={ onTCCancel }
        closeable
        status="none"
        spacing="loose"
        allowBackgroundInteraction
        className="flex-column justify-center md:mt-10 md:mb-10 relative"
      >
        <div
          slot="title"
          tabIndex={ 0 }
        >
          <FormattedMessage
            id="terms_conditions_label"
            defaultMessage="Terms & Conditions"
          />
        </div>
        <div className="text-lg">{ parseHtml(entities.decode(tcText)) }</div>
        <div
          slot="custom-buttons"
          className="flex flex-col-reverse sm:flex-row"
        >
          <SdfButton
            className="text-md ml-4 mt-4 sm:mt-0"
            id="tcCancelBtn"
            onClick={ onTCCancel }
          >
            <FormattedMessage
              id="olp_cancel"
              defaultMessage="Cancel"
              description="Cancel button"
            />
          </SdfButton>
          <SdfButton
            className="text-md ml-4"
            id="tcAcceptBtn"
            onClick={ onTCAccept }
            emphasis="primary"
          >
            <FormattedMessage
              id="terms_conditions_accept"
              defaultMessage="I Agree"
            />
            <SdfIcon
              icon="action-confirm"
              className="pl-1"
            />
          </SdfButton>
        </div>
      </SdfFocusPane>
    );
  }
}

TCForm.propTypes = {
  currentlySending: PropTypes.bool,
  tcChallenge: PropTypes.shape({
    text: PropTypes.string,
  }),
  tcCancel: PropTypes.func.isRequired,
  tcAccept: PropTypes.func.isRequired,
};

export default TCForm;
